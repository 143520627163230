import React from 'react'
import css from '../assets/css.png'
import html from '../assets/html.png'
import javascript from '../assets/javascript.png'
import typescript from '../assets/typescript.png'
import react from '../assets/react.png'
import tailwind from '../assets/tailwind.png'
import git from '../assets/git.jpeg'
import vue from '../assets/vue.png'
import reactNative from '../assets/react-native.png'
import php from '../assets/php.png'
import node from '../assets/node.png'
import next from '../assets/next.png'
import wordpress from '../assets/wordpress.webp'

const Skills = () => {
  return (
    <div name="skills" className="bg-[#0a192f] w-full h-screen text-gray-300 pb-32 md:pb-0">
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div>
            <p className="text-4xl font-bold inline border-b-4 border-blue-700">Skills</p>
            <p className="py-4">These are my top technologies I've worked with</p>
        </div>
        <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-4 text-center py-8">
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
                <img className="w-20 mx-auto" src={html} alt="html"></img>
                <p clasName="my-4">HTML</p>
            </div>
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
                <img className="w-20 mx-auto" src={css} alt="css"></img>
                <p clasName="my-4">CSS</p>
            </div>
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
                <img className="w-20 mx-auto" src={javascript} alt="javascript"></img>
                <p clasName="my-4">Javascript</p>
            </div>
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
                <img className="w-20 mx-auto" src={typescript} alt="typescript"></img>
                <p clasName="my-4">Typescript</p>
            </div>
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
                <img className="w-20 mx-auto" src={react} alt="react"></img>
                <p clasName="my-4">React</p>
            </div>
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
                <img className="w-20 mx-auto" src={tailwind} alt="tailwind"></img>
                <p clasName="my-4">Tailwind</p>
            </div>
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
                <img className="w-20 mx-auto" src={git} alt="git"></img>
                <p clasName="my-4">Git</p>
            </div>
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
                <img className="w-20 mx-auto h-[60px]" src={reactNative} alt="react native"></img>
                <p clasName="my-3">React Native</p>
            </div>
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
                <img className="w-20 mx-auto" src={php} alt="php"></img>
                <p clasName="my-4">PHP</p>
            </div>
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
                <img className="w-20 mx-auto" src={node} alt="node"></img>
                <p clasName="my-4">Node</p>
            </div>
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
                <img className="w-20 mx-auto" src={next} alt="next"></img>
                <p clasName="my-4">Next</p>
            </div>
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
                <img className="w-20 mx-auto mb-3" src={vue} alt="vue"></img>
                <p clasName="my-4">Vue</p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Skills
