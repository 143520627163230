import React from 'react'
import bonVoyage from '../assets/websitePicture1.png'
import codeScool from '../assets/websitePicture2.png'
import Selene from '../assets/websitePicture3.png'
import parla from '../assets/websitePicture4.png'
import funKids from '../assets/websitePicture5.png'
import cafeFrankies from '../assets/websitePicture6.png'
         
const Work = () => {
  return (
    <div name="work" className="w-full md:h-screen text-gray-300 bg-[#0a192f]">
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div className="pb-4">
            <p className="text-4xl font-bold border-b-4 text-gray-300 border-blue-700 inline">Work</p>
            <p className="py-6">My portfolio showcases a collaborative body of work, 
            highlighting my contributions to a range of projects undertaken 
            collectively by our skilled software engineering team, demonstrating 
            our commitment to innovation and excellence.</p>
        </div>

        {/*} Container {*/}
        <div className="grid sm:grid-cols-2 gap-4">

            {/*} Items {*/}
            
            <div style={{backgroundImage: `url(${bonVoyage})`}} className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div">
                
                <div className="opacity-0 group-hover:opacity-100">
                    <span className="text-2xl font-bold text-black tracking-wider">
                        Limousine Services
                    </span>
                    <div className="pt-8 text-black text-center">
                        <a href="https://www.bonvoyagelimousine.com/" target="_blank" rel="noreferrer">
                          <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                            Website</button>
                        </a>
                    </div>
                </div>
            </div>

            <div style={{backgroundImage: `url(${codeScool}`}} className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div">
                
                <div className="opacity-0 group-hover:opacity-100">
                    <span className="text-2xl font-bold text-black tracking-wider">
                        Codescool
                    </span>
                    <div className="pt-8 text-black text-center">
                        <a href="https://www.codescool.io/" target="_blank" rel="noreferrer">
                          <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                            Website</button>
                        </a>
                    </div>
                </div>
            </div>



            <div style={{backgroundImage: `url(${Selene})`}} className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div text-center">
                
                <div className="opacity-0 group-hover:opacity-100">
                    <span className="text-2xl font-bold text-center text-black tracking-wider">
                      Selene
                    </span>
                    <div className="pt-8 text-black text-center">
                        <a href="https://selene-production.up.railway.app" target="_blank" rel="noreferrer">
                          <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                            Website</button>
                        </a>
                    </div>
                </div>
            </div>

            <div style={{backgroundImage: `url(${parla})`}} className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div">
                
                <div className="opacity-0 group-hover:opacity-100">
                    <span className="text-2xl font-bold text-center text-black tracking-wider">
                      Parla Chatbot
                    </span>
                    <div className="pt-8 text-black text-center">
                        <a href="https://parla.up.railway.app/" target="_blank" rel="noreferrer">
                          <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                            Website</button>
                        </a>
                    </div>
                </div>
            </div>

            <div style={{backgroundImage: `url(${funKids})`}} className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div">
                
                <div className="opacity-0 group-hover:opacity-100">
                    <span className="text-2xl font-bold text-center text-black tracking-wider">
                      Fun Kids
                    </span>
                    <div className="pt-8 text-black text-center">
                        <a href="https://fun-kids-production.up.railway.app/" target="_blank" rel="noreferrer">
                          <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                            Website</button>
                        </a>
                    </div>
                </div>
            </div>

            <div style={{backgroundImage: `url(${cafeFrankies})`}} className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div">
                
                <div className="opacity-0 group-hover:opacity-100">
                    <span className="text-2xl font-bold text-center text-black tracking-wider">
                      Cafe Frankies
                    </span>
                    <div className="pt-8 text-black text-center">
                        <a href="https://www.cafefrankies.com/" target="_blank" rel="noreferrer">
                          <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                            Website</button>
                        </a>
                    </div>
                </div>
            </div>

        </div>
        </div>

      </div>
  )
}

export default Work
